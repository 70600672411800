import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { withPrefix } from 'gatsby';
import { Grid, MinimalCard } from '../../shared/card-grid';
export const _frontmatter = {
  "title": "Join the team",
  "layout": "aboutpage",
  "banner": "/images/core/team_banner.jpg",
  "banner_caption": "info"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`The Advanced Concepts Team researchers are recruited as Research Fellows (RFs), Young Graduate Trainees (YGTs) or Interns using existing European Space Agency channels and mechanisms. All positions are for a limited amount of time: 2 years for RFs, 1 year for YGTs, and around 3 months for Interns.`}</p>
    <Grid mdxType="Grid">
  <MinimalCard src="/images/core/rf_fellow_button.png" to="/about/research_fellows" call="Research Fellows" mdxType="MinimalCard" />
  <MinimalCard src="/images/core/ygt_button.png" to="/about/ygts" call="Young Graduate Trainees" mdxType="MinimalCard" />
  <MinimalCard src="/images/core/stagiaire_button.png" to="/about/interns" call="Interns" mdxType="MinimalCard" />
    </Grid>
    <hr></hr>
    <h2>{`Research Fellowships (RFs) at the ACT`}</h2>
    <p>{`Research Fellows are PhD's (post-doc) working at ESA for 2 years under the existing ESA's Fellowship scheme.`}</p>
    <p>{`ACT research fellows develop their activity on advanced research topics, requiring both space systems engineering competence and specific theoretical knowledge. Interaction with universities and research institutes -both in Europe and worldwide-is an important aspect of ACT activities. ACT research fellows are therefore expected to have and establish strong links with the academic community outside the natural ones of their originating University. In order to enhance cross-fertilisation between different study areas, interdisciplinary research work is an essential part of the ACT philosophy. Research fellows working in the ACT often have to deal with topics that are not strictly related to their field of expertise. The work requires creativity, open-mindedness and naturally curiosity, as well as a strong aptitude to team work. Typically, researchers continue their career within academia and research centres after the research fellowship stay in the ACT.`}</p>
    <p>{`Typically ACT research fellows share their time and energy between`}</p>
    <ol>
      <li parentName="ol">{`their own core research, usually proposed and led by the fellow with participation by other researchers from the team,`}</li>
      <li parentName="ol">{`team research projects where they contribute with their specific expertise, and`}</li>
      <li parentName="ol">{`team activities related to the role of the team as internal think-tanks within ESA. For research projects requiring external expertise not readily available within the team or ESA, the Ariadna scheme can be used for collaborative research with European universities.`}</li>
    </ol>
    <p>{`ACT Research Fellowships are only open to citizens from ESA Member States or ESA Cooperating States.`}</p>
    <h3>{`Why should you apply for the post doc position in our team?`}</h3>
    <p>{`Leopold Summerer explains why biologists, physicists and researchers from fields not traditionally related to space should apply for a post doc position in the Advanced Concepts Team (ACT).`}</p>
    <iframe src="https://www.esa.int/spaceinvideos/content/view/embedjw/442304" width="640" height="360" frameBorder="0"></iframe>
    <p>{`More videos about who we are and what we are doing can be found in the links below:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.esa.int/spaceinvideos/Videos/2015/01/Of_bees_and_lunar_landers_biomimetics_and_space"
        }}>{`Of bees and lunar landers’ – biomimetics and space`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.esa.int/spaceinvideos/Videos/2015/01/My_heart_beats_for_mathematics"
        }}>{`My heart beats for mathematics`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.esa.int/spaceinvideos/Videos/2015/01/Finding_solutions_for_unsolved_problems"
        }}>{`Finding solutions for unsolved problems`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.esa.int/spaceinvideos/Videos/2015/01/An_optical_affair_what_links_photonics_to_planetary_system_science"
        }}>{`An optical affair – what links photonics to planetary system science?`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.esa.int/spaceinvideos/Videos/2015/01/Time_and_space_-_research_on_gravitation_spurs_innovation"
        }}>{`Time and space - research on gravitation spurs innovation`}</a></li>
    </ul>
    <hr></hr>
    <h2>{`ESA's Young Graduate Trainee (YGT) programme`}</h2>
    <p>{`This YGT programme offers recently graduates a one-year non-renewable training contract designed to give valuable work experience and to prepare for future employment in the space industry and/or research. Working in the ACT offers the opportunity of working in an exciting international and multicultural environment. During his year of training the candidate is assigned to one main project but is also exposed to a number of different ideas/projects in all the main ACT categories. Graduate trainees typically work as part of a team under the supervision of an experienced specialist or 'mentor'. To apply for a YGT position in the ACT you must follow the general `}<a parentName="p" {...{
        "href": "https://www.esa.int/About_Us/Careers_at_ESA/Graduates_Young_Graduate_Trainees"
      }}>{`ESA YGT recruitment process`}</a></p>
    <hr></hr>
    <h2>{`ESA's Internship Programme`}</h2>
    <p>{`This `}<a parentName="p" {...{
        "href": "https://www.esa.int/About_Us/Careers_at_ESA/Student_Internships2"
      }}>{`ESA Programme`}</a>{` assists young people from its Member States interested in space and related areas. It helps them to meet their university requirements to carry out an internship as part of their degree, and promotes their academic and professional development by placing them under the guidance of experienced professionals. Interns in the Advanced Concepts Team foster the research in new technologies and concepts for future space applications. ACT Internships are only open to citizens from ESA Member States or ESA Cooperating States.`}</p>
    <hr></hr>
    <h2>{`Post-ACT Careers`}</h2>
    <p>{`After the period spent in the ACT, our researchers typically go back to academia. Some start a career in industry.`}</p>
    <object type="image/svg+xml" data={withPrefix("/images/about/jointheteam/pie.svg")} style={{
      "width": "100%"
    }} />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      