import React from 'react'
import { BlogPage } from '../../shared/page-commons'
import MDBody, { _frontmatter } from './_jointheteam.md'

export default function JoinTheTeamPage(props) {
  return (
    <BlogPage frontmatter={_frontmatter}>
      <MDBody />
    </BlogPage>
  )
}
